<script>
  export default {
    components: {
      FiltersBarInLive: () => import("./FiltersBarInLive.vue"),
      CardStatisticAvailable: () => import("./CardStatisticAvailable.vue"),
      CardStatisticByPlatform: () => import("./CardStatisticByPlatform.vue"),
      CardStatisticBusyBar: () => import("./CardStatisticBusyBar.vue"),
    },
    data() {
      return {
        msg: "porcentaje de ocupacíon global",
        showPlatforms: true,
        isCollapseClosing: {},
        key: 0,
        isLoaded: true,
        collapsedCitiesInfo: [],
        availableOptions: [
          { text: "Disponibilidad", value: "" },
          { text: "Disponible", value: "disponibles" },
          { text: "Ocupadas", value: "ocupadas" },
          { text: "Deshabilitada", value: "deshabilitadas" },
        ],
        satelites: [],
      };
    },
    computed: {
      rooms() {
        return this.$store.getters["rooms/getData"];
      },
      roomsData() {
        return this.getRoomsFilteredWithModels(this.rooms);
      },
      totalModelsGlobal() {
        return this.$store.state.users.data.filter((model) => model.office === "Satelite");
      },
      totalsRooms() {
        const occupiedRooms = this.roomsData.filter((room) => room?.modelTemp?._id);
        const availableRooms = this.roomsData.filter((room) => !room?.modelTemp?._id && room?.isEnable);
        const disabledRooms = this.roomsData.filter((room) => !room?.isEnable);

        return {
          ocupadas: occupiedRooms.length,
          disponibles: availableRooms.length,
          deshabilitadas: disabledRooms.length,
        };
      },
      avgBusy() {
        return this.roomsData.length === 0 ? 0 : Math.round((this.totalsRooms.ocupadas / this.roomsData.length) * 100);
      },
      modelsGlobalConected() {
        return this.roomsWithGlobalData.filter((model) => model.cityName === "Global" && typeof model.modelTemp !== "undefined");
      },
      avergareGlobal() {
        const { totalModelsGlobal, modelsGlobalConected } = this;
        if (totalModelsGlobal.length === 0) {
          return 0;
        }
        const average = Math.round((modelsGlobalConected.length / totalModelsGlobal.length) * 100);
        return average;
      },
      roomsWithGlobalData() {
        const roomBasic = {
          isEnable: true,
          cityName: "Global",
          officeName: "Satelite",
        };
        const sateliteRooms = this.satelites.map(({ artisticName, user }) => ({ _id: user, roomName: artisticName, ...roomBasic }));
        return this.getRoomsFilteredWithModels([...this.rooms, ...sateliteRooms]);
      },
      reducedData() {
        const data = this.roomsWithGlobalData.reduce((acc, item) => {
          const { cityName, officeName, modelTemp } = item;
          const cityData = acc[cityName] || (acc[cityName] = {});
          const officeData = cityData[officeName] || (cityData[officeName] = {});

          officeData.platform = officeData.platform || { jasmin: 0, stremeate: 0 };
          officeData.users = officeData.users || { total: 0, users: 0 };
          officeData.rooms = officeData.rooms || [];

          if (modelTemp?.isBroadcastingOnStreamate) {
            officeData.platform.stremeate += 1;
          }
          if (modelTemp?.isBroadcastingOnJasmin) {
            officeData.platform.jasmin += 1;
          }
          if (modelTemp?._id) {
            officeData.users.users += 1;
          }

          officeData.users.total += 1;
          officeData.rooms.push(item);
          return acc;
        }, {});
        return data;
      },
      roomsInOficces() {
        const data = this.roomsData.reduce((acc, item) => {
          const office = item.officeName;

          if (!acc?.[office]) {
            acc[office] = [];
          }

          acc[office].push(item);
          return acc;
        }, {});
        return data;
      },
    },
    methods: {
      async getSatelites() {
        const { data } = await this.$axios.get(`/userslist?city=Global`);
        const { users, error } = data;
        this.satelites = users || [];
      },
      closeCollapse() {
        if (this.$screen.width <= 430 && !this.collapsedCitiesInfo.length) {
          const executeReduce = (sede) => this.manageCityCollapse(sede);
          Object.values(this.reducedData).forEach((sedes) => {
            Object.keys(sedes).forEach(executeReduce);
          });
          this.isLoaded = false;
        }
      },
      getStatusRoom(room) {
        const model = room?.modelTemp || {};
        if (!room.isEnable) {
          return "disabled";
        } else if (model?.isBreak) {
          return "break";
        } else if (model?._id) {
          return "busy";
        }
      },
      getModel(room) {
        if (!room?.modelTemp?._id) {
          return null;
        }
        return room?.modelTemp;
      },
      /**
       * Esta funcion es la encargada de devolver una clase dependiendo del estado de la habitacion
       * y de la plataforma en la que la modelo este stremeando
       * @param {*} room informacion de la habitacion
       */
      getClassByStatus(room) {
        const roomStatus = this.getStatusRoom(room);
        if (roomStatus === "busy") {
          const model = room?.modelTemp || {};
          const { platform = null } = this.$route.query;
          const platformMap = {
            bothPlatforms: model.isBroadcastingOnJasmin && model.isBroadcastingOnStreamate && platform === "double",
            jasmin: model.isBroadcastingOnJasmin && platform === "jasmin",
            streamate: model.isBroadcastingOnStreamate && platform === "stremeate",
          };

          for (const key in platformMap) {
            if (platformMap[key]) {
              return key;
            }
          }
        }
        return roomStatus;
      },
      showPlatformsToggle() {
        this.showPlatforms = !this.showPlatforms;
      },
      getStreamingPlatforms(model) {
        const { platform = null } = this.$route.query;

        if (platform) {
          return platform == "double" ? ["stremeate", "jasmin"] : [platform];
        }

        const platformsInStream = [];

        if (model?.isBroadcastingOnStreamate) {
          platformsInStream.push("stremeate");
        }
        if (model?.isBroadcastingOnJasmin) {
          platformsInStream.push("jasmin");
        }
        return platformsInStream;
      },
      nameInRoom(model, statusRoom, room) {
        const names = {
          disabled: () => "Deshabilitada",
          break: () => model?.artisticName,
          busy: () => model?.artisticName,
        };

        const nameReturn = () => this.removeBlanks(names?.[statusRoom]?.()) || "Disponible";
        const nameGlobal = () => (!model?.artisticName ? "Desconectada" : "");

        return room?.cityName === "Global" ? nameGlobal() : nameReturn();
      },
      getIconName(model) {
        return model?.isBreak ? "pause" : model?.isStreaming ? "play" : "";
      },
      removeBlanks(str) {
        return str?.replace(/\s+/g, "") || "";
      },

      getRoomsFilteredWithModels(rooms) {
        const roomsTest = structuredClone(rooms);
        const { query } = this.$route;
        const filterSwichAvailable = {
          disponibles: (room) => room.isEnable && !room?.modelTemp?._id,
          deshabilitadas: (room) => !room.isEnable,
          ocupadas: (room) => !!room?.modelTemp?._id,
        };
        const filterSwichPlatform = {
          jasmin: (room) => room?.modelTemp && room?.modelTemp?.isBroadcastingOnJasmin,
          stremeate: (room) => room?.modelTemp && room?.modelTemp?.isBroadcastingOnStreamate,
          double: (room) => room?.modelTemp && room?.modelTemp?.isBroadcastingOnJasmin && room?.modelTemp?.isBroadcastingOnStreamate,
          none: (room) => room?.modelTemp && !room?.modelTemp?.isBroadcastingOnJasmin && !room?.modelTemp?.isBroadcastingOnStreamate,
        };

        const roomsFiltered = roomsTest.filter((room) => {
          const isFilterCity = query?.city ? query.city === room.cityName : true;
          const isFilterOffice = query?.office ? query.office === room.officeName : true;
          const isFilterRoom = query?.room ? query.room === room.roomName : true;

          return isFilterCity && isFilterOffice && isFilterRoom;
        });

        const modelrooms = this.$store.getters["connectedUsers/getModelsInRoom"];
        const modelsMap = {};

        modelrooms.forEach((model) => model?.roomTemp?._id && (modelsMap[model.roomTemp._id] = model));

        const roomsFilterBusy = roomsFiltered.filter((room) => {
          if (modelsMap[room._id]) {
            room.modelTemp = modelsMap[room._id];
          }

          const isFilterAvailable = query?.available ? filterSwichAvailable?.[query.available]?.(room) : true;
          const isFilterPlatform = query?.platform ? filterSwichPlatform?.[query.platform]?.(room) : true;
          return isFilterAvailable && isFilterPlatform;
        });

        return roomsFilterBusy;
      },
      showCleanBtn({ flag, platform }) {
        this.$refs.filterBarInLive.platform = flag ? platform : "";
        this.$refs.filterBarInLive.executeFilter("platform");
      },
      manageCityCollapse(cityIndex) {
        if (this.isCollapseClosing[cityIndex]) return;

        this.isCollapseClosing[cityIndex] = true;

        const findIndexCity = this.collapsedCitiesInfo.findIndex((city) => city === cityIndex);
        const fatherHeight = document.getElementById(`wrapper-${cityIndex}`).offsetHeight;
        if (findIndexCity != -1) this.collapsedCitiesInfo.splice(findIndexCity, 1);
        else {
          this.collapsedCitiesInfo.push(cityIndex);
        }
        this.toggleElementCollapse({
          fatherIdOrClass: `#container-${cityIndex}`,
          fatherHeight,
          isCollapseToClose: findIndexCity === -1,
          fatherHeightCollapsed: 74,
          gapValue: 7.5,
          key: cityIndex,
        });
      },

      toggleElementCollapse(collapseInfo) {
        const { fatherIdOrClass, fatherHeight, isCollapseToClose, fatherHeightCollapsed, gapValue = 0, key } = collapseInfo;
        const interval = setInterval(() => {
          const fatherElement = document.querySelector(fatherIdOrClass);
          let currentFatherHeight = fatherElement.offsetHeight;
          if (isCollapseToClose) {
            const realFatherHeightCollapsed = fatherHeightCollapsed - gapValue;
            if (currentFatherHeight > realFatherHeightCollapsed) {
              fatherElement.style.height = `${currentFatherHeight - 5}px`;
            } else {
              this.isCollapseClosing[key] = false;
              fatherElement.style.height = realFatherHeightCollapsed + "px";
              clearInterval(interval);
            }
          } else {
            const realFatherHeightCollapsed = fatherHeightCollapsed + gapValue;
            if (currentFatherHeight <= fatherHeight) {
              fatherElement.style.height = currentFatherHeight + 5 + "px";
            } else {
              const resta = ["available", "platforms"].includes(key) ? 0 : realFatherHeightCollapsed;

              fatherElement.style.height = fatherHeight + resta + "px";
              this.isCollapseClosing[key] = false;
              clearInterval(interval);
            }
          }
        }, 1);
      },

      findIndexCity(index) {
        return this.collapsedCitiesInfo.some((city) => city == index);
      },
    },
    async beforeMount() {
      await this.getSatelites();
    },
    watch: {
      $route() {
        this.key++;
      },
    },
  };
</script>

<template>
  <section class="occupationInLive" :key="key">
    <FiltersBarInLive ref="filterBarInLive" />
    <CardStatisticBusyBar :avgBusy="avgBusy" v-if="$screen.width <= 430" />
    <CardStatisticBusyBar :avgBusy="avergareGlobal" :msg="msg" v-if="$screen.width <= 430" />
    <div class="occupationInLive__statistics">
      <CardStatisticAvailable class="card__statistic" :totalsRooms="totalsRooms" :total="roomsData.length" :global="modelsGlobalConected.length" @toggleElementCollapse="toggleElementCollapse" />
      <CardStatisticByPlatform
        class="card__statistic"
        :roomsData="roomsWithGlobalData"
        @showCleanBtn="showCleanBtn"
        @toggleElementCollapse="toggleElementCollapse"
        :showPlatformsToggle="showPlatformsToggle"
        :showPlatforms="showPlatforms"
      />
    </div>
    <div class="occupationInLive__statistics">
      <CardStatisticBusyBar :avgBusy="avgBusy" v-if="$screen.width > 430" />
      <CardStatisticBusyBar :avgBusy="avergareGlobal" :msg="msg" v-if="$screen.width > 430" />
    </div>
    <div :class="['occupationInLive__cityCard']" v-for="(city, cityKey) in reducedData" :key="cityKey">
      <div class="occupationInLive__header">
        <h3 class="occupationInLive__title">{{ cityKey }}</h3>
      </div>
      <div class="occupationInLive__officeCards ">
        <div :class="['occupationInLive__officeCard', 'collapse__container', { 'collapse-active': findIndexCity(key) }]" v-for="(office, key) in city" :key="key" :id="`container-${key}`">
          <div class="occupationInLive__officeHeader collapse__header" :id="`header-${key}`">
            <div class="occupationInLive__officeHeader--info">
              <div class="occupationInLive__officeData">
                <span class="occupationInLive__firstDetail">{{ key }}</span>
                <span class="occupationInLive__secDetail">{{ `${office.users.users} / ${office.users.total}` }}</span>
              </div>
              <div class="occupationInLive__officeData occupationInLive__officeData--platformData">
                <div class="occupationInLive__firstDetail">
                  <div class="occupationInLive__contentIcon">
                    <iconic class="occupationInLive__iconPlatformOffice occupationInLive__iconPlatformOffice--jasmin" name="play" />
                  </div>
                  <span>{{ office.platform.jasmin }}</span>
                </div>
                <span>|</span>
                <div class="occupationInLive__secDetail">
                  <div class="occupationInLive__contentIcon">
                    <iconic class="occupationInLive__iconPlatformOffice occupationInLive__iconPlatformOffice--streamate" name="play" />
                  </div>
                  <span>{{ office.platform.stremeate }}</span>
                </div>
              </div>
            </div>
            <button class="collapse__button" type="button" @click="manageCityCollapse(key)"><iconic name="chevronUp" /></button>
          </div>
          <div class="occupationInLive__contentRooms collapse__body" :id="`wrapper-${key}`">
            <div
              :class="`occupationInLive__cardInLive occupationInLive__cardInLive--${getClassByStatus(room)}`"
              v-for="room in office.rooms"
              :set="((model = getModel(room)), (statusRoom = getStatusRoom(room)), (iconName = getIconName(model)))"
              :key="room._id"
            >
              <div class="occupationInLive__roomCardAvatarContainer" v-if="model">
                <template v-if="false">
                  <iconic
                    :class="`occupationInLive__iconPlatform occupationInLive__iconPlatform--streamate occupationInLive__iconPlatform--${model.isBroadcastingOnStreamate ? 'isInPlatform' : ''}`"
                    name="stremeate"
                    v-if="model.jasminUser"
                  />
                  <iconic
                    :class="`occupationInLive__iconPlatform occupationInLive__iconPlatform--jasmin occupationInLive__iconPlatform--${model.isBroadcastingOnJasmin ? 'isInPlatform' : ''}`"
                    name="jasmin"
                    v-if="model.streamateUser"
                  />
                </template>
                <Avatar class="occupationInLive__roomCardAvatar" :user="model" />
              </div>
              <div :class="`occupationInLive__roomCardInfo occupationInLive__roomCardInfo--${model ? 'busy' : ''}`">
                <span>{{ nameInRoom(model, statusRoom, room) }}</span>
                <div :class="`occupationInLive__contentItem occupationInLive__contentItem--${model ? 'busy' : ''}`">
                  <span :class="`occupationInLive__roomName occupationInLive__roomName--${getClassByStatus(room)}`">{{ room.roomName }}</span>
                  <template v-if="iconName && showPlatforms">
                    <div class="occupationInLive__roomCardIcons occupationInLive__roomCardIcons--platforms">
                      <iconic
                        :class="`occupationInLive__roomCardIcon occupationInLive__roomCardIcon--${platform}`"
                        v-for="(platform, index) in getStreamingPlatforms(model)"
                        :key="index"
                        :name="iconName"
                      />
                    </div>
                  </template>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<style lang="scss">
  .occupationInLive {
    @include Flex(column, flex-start);
    width: 100%;
    max-width: $tv_width;
    gap: $mpadding;
    margin-bottom: 50px;
    .collapse__container {
      .collapse__header {
        height: 60px;

        position: relative;
        z-index: 10;
      }
      .collapse__button {
        all: unset;
        display: flex;
        cursor: pointer;
        transition: transform 0.3s ease-in-out;
        .iconic {
          font-size: 22px;
        }
      }

      &.collapse-active {
        overflow: hidden;
        .collapse__button {
          transform: rotate(180deg);
        }
      }
    }

    &__header {
      display: flex;
      @include Flex(row, space-between, center);
      width: 100%;
      padding: 15px;
    }
    &__statistics {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
      width: 100%;
      gap: 15px;
    }
    &__cityCard {
      width: 100%;
      background: white;
      box-shadow: 0px 1px 4px 0px #00000040;
      border-radius: 8px;
    }
    &__title {
      font-weight: lighter;
    }
    &__officeCards {
      padding: 9px;
    }
    &__officeCard {
      @include Flex(column, flex-start, flex-start);
      position: relative;
      gap: $mpadding / 2;
      width: 100%;
      margin-bottom: $mpadding/2;
      padding: $mpadding/2;
      background: #f2f2f2;
      border-radius: 9px;
    }
    &__officeHeader {
      width: 100%;
      padding: 15px 0;
      &,
      &--info {
        @include Flex(row, space-between, center);
        gap: 10px;
      }
      .collapse__button {
        justify-self: flex-end;
      }
    }
    &__officeData {
      @include Flex(row);
      height: 25px;
      padding: 0 8px;
      font-family: $sec-font;
      font-size: 20px;
      color: #fff;
      background: $primary-color;
      border-radius: 9px;
      &--platformData {
        background: white;
        border: 2px solid black;
        color: #000;
        padding: 12px;
      }
    }
    &__firstDetail {
      @include Flex(row);
      padding: 0 10px;
      gap: 7px;
    }
    &__secDetail {
      position: relative;
      @include Flex(row);
      gap: 7px;
      padding: 0 10px;
      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        margin: auto;
        width: 1px;
        height: 65%;
        background-color: #fff;
      }
    }
    &__contentIcon {
      @include Flex(row);
      width: 18px;
      height: 18px;
      border-radius: 50%;
      background: #fff;
    }
    &__iconPlatformOffice {
      font-size: 18px;
      &--jasmin {
        color: #bd0909;
      }
      &--streamate {
        color: #21619a;
      }
    }
    &__contentRooms {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
      width: 100%;
      gap: $mpadding / 2;
    }
    &__cardInLive {
      @include Flex(row);
      gap: 5px;
      padding: 8px;
      text-transform: capitalize;
      background: rgba(32, 158, 12, 0.05);
      border: 2px solid #209e0c;
      border-radius: 7px;
      transition: 0.5s ease-in-out;
      &--disabled {
        background: rgba(157, 157, 157, 0.24);
        border-color: #9d9d9d;
      }
      &--busy {
        background: rgba(189, 9, 9, 0.05);
        border-color: #bd0909;
      }
      &--break {
        background: rgba(238, 187, 4, 0.09);
        border-color: #eebb04;
      }
      &--bothPlatforms {
        background: rgb(251, 245, 253);
        border-color: rgb(161, 85, 185);
      }
      &--jasmin {
        background: rgba(189, 9, 9, 0.05);
        border-color: #bd0909;
      }
      &--streamate {
        background: #21619a17;
        border-color: #21619a;
      }
    }
    &__roomCardInfo {
      @include Flex(column);
      width: 100%;
      font-size: 13px;
      text-overflow: ellipsis;
      overflow: hidden;
      &--busy {
        width: calc(100% - 45px);
        align-items: flex-start;
      }
    }
    &__contentItem {
      @include Flex(row);
      width: 100%;
      transition: 0.5s ease-in-out;
      &--busy {
        justify-content: space-between;
      }
    }
    &__roomName {
      font-weight: 600;
      color: #209e0c;
      transition: 0.5s ease-in-out;
      max-width: 95%;
      text-overflow: ellipsis;
      overflow: hidden;
      &--disabled {
        color: black;
      }
      &--busy {
        color: $primary-color;
      }
      &--break {
        color: #eebb04;
      }
      &--bothPlatforms {
        color: #a155b9;
      }
      &--jasmin {
        color: #bd0909;
      }
      &--streamate {
        color: #21619a;
      }
    }
    &__roomCardIcons {
      display: flex;
      position: relative;
      .occupationInLive__roomCardIcon {
        transition: all 0.3s ease-in-out;
        font-size: 18px;
        &:not(:last-of-type) {
          display: flex;
          justify-content: space-between;
          margin-right: -9px;
          z-index: 10;
        }
        &--jasmin {
          color: #bd0909;
        }
        &--stremeate {
          color: #21619a;
        }
      }
    }
    &__roomCardIcon {
      color: #eebb04;
      &--play {
        color: $primary-color;
      }
    }
    &__roomCardAvatarContainer {
      position: relative;
      @include Flex(row);
    }
    &__roomCardAvatar {
      width: 35px;
      height: 35px;
      border: 1px solid $white;
    }
    &__iconPlatform {
      @include Flex(row);
      position: absolute;
      left: -9px;
      z-index: 10;
      color: white;
      background-color: black;
      padding: 2px;
      border-radius: 50%;
      opacity: 0.5;
      &--stremeate {
        top: -2px;
      }
      &--jasmin {
        bottom: -2px;
      }
      &--isInPlatform {
        opacity: 1;
      }
    }
    @media screen and (min-width: $tablet-width) {
      &__contentRooms {
        grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
        padding: $mpadding / 2;
        gap: $mpadding / 1.3;
      }
      &__statistics {
        .card__statistic {
          height: 330px;
        }
      }
      &__officeCards {
        padding: 15px;
      }
      &__officeHeader {
        padding: 15px;
      }
    }
  }
</style>
